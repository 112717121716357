import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsalInterceptor } from '@azure/msal-angular';
import { AuthType } from 'src/app/modules/login/auth-green.service';

@Injectable()
export class MsalOverridedInterceptor extends MsalInterceptor implements HttpInterceptor {
  override intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const authType = localStorage.getItem('AUTH_TYPE') || '';

    if (+authType === AuthType.EMAIL) {
      return next.handle(request);
    }

    return super.intercept(request, next);
  }
}
