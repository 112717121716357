import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public isLoading = new BehaviorSubject(false);
  public isLoadingNonBlocking = new BehaviorSubject(false);

  constructor() {}

  public show(): void {
    this.isLoading.next(true);
  }

  public hide(): void {
    this.isLoading.next(false);
  }

  public showNonBlocking(): void {
    this.isLoadingNonBlocking.next(true);
  }

  public hideNonBlocking(): void {
    this.isLoadingNonBlocking.next(false);
  }
}
