import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from './core/store/state';
import {
  selectAuthenticatedAccount,
  selectGreenAuthUser,
  selectLicenseExpired,
  selectLoginning,
  selectOnlyEmailAuthOption,
  selectUser,
} from './core/store/selectors';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { TranslocoService } from '@ngneat/transloco';
import { AuthActions, GetUserActions } from './core/store/actions';
import { Subject, combineLatest, filter, takeUntil, throttleTime } from 'rxjs';
import { AppButtonSize, AppButtonType } from './shared/shared.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPresent } from './core/utils/isPresent';
import { AuthGreenService, AuthType } from './modules/login/auth-green.service';
import { Router } from '@angular/router';
import { ApplicationPaths } from './modules/login/api-authorization.constants';
import { SharedActions } from './shared/store/actions';
import * as version from '../environment/versions';
import { constants } from './core/constants/constants';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public AuthType = AuthType;
  public isUserDetails: boolean = false;
  public isLicenseExpired: boolean | null = null;
  public isAuthenticatedAccount: boolean = false;
  public prevSuccessAuthResultToken: any;
  public greenAuthUser: any;
  public onlyEmailAuthOption: boolean = false;
  public loginning: boolean = false;
  public loading: boolean = false;
  public constants = constants;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private titleService: Title,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    private authGreenService: AuthGreenService,
  ) {}

  ngOnInit() {
    console.log('version: ' + JSON.stringify(version));
    this.titleService.setTitle('AutoLex');
    this.setAuthAccount();
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    combineLatest([
      this.authGreenService.getUser().pipe(filter(isPresent)),
      this.authGreenService.getAccessToken().pipe(filter(isPresent)),
      this.store$.select(selectGreenAuthUser),
      this.store$.select(selectAuthenticatedAccount),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([greenUser, greenAccessToken, storedGreenUser, authenticatedAccount]) => {
        if (!!greenUser && !!greenAccessToken && !storedGreenUser && !authenticatedAccount) {
          // user auth by email and refresh the page
          this.store$.dispatch(
            AuthActions.setGreenAuthUser({
              user: { ...greenUser, access_token: greenAccessToken },
            }),
          );
        }
      });
    // this.store$
    //   .select(selectIsGettingUserDetails)
    //   .pipe(untilDestroyed(this), filter(isPresent))
    //   .subscribe((isGetting) => {
    //     this.loading = isGetting;
    //   });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
        untilDestroyed(this),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setAuthAccount();
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        untilDestroyed(this),
      )
      .subscribe((result: EventMessage) => {
        this.store$.dispatch(AuthActions.stopAuthorizing());
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.setAuthAccount();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe((result: EventMessage) => {
        this.processSuccess(result);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        this.processSuccess(result);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS))
      .subscribe((result: EventMessage) => {
        this.processSuccess(result);
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.setAuthAccount();
      });

    this.store$
      .select(selectUser)
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe((user) => {
        this.isUserDetails = !!user;
      });

    this.store$
      .select(selectLicenseExpired)
      .pipe(untilDestroyed(this))
      .subscribe((expired) => {
        this.isLicenseExpired = expired;
      });

    this.store$
      .select(selectLoginning)
      .pipe(untilDestroyed(this))
      .subscribe((loginning) => {
        this.loginning = loginning;
      });

    this.store$
      .select(selectOnlyEmailAuthOption)
      .pipe(untilDestroyed(this))
      .subscribe((onlyEmailAuthOption) => {
        this.onlyEmailAuthOption = !!onlyEmailAuthOption;

        if (this.onlyEmailAuthOption) {
          this.store$.dispatch(AuthActions.setLoginning({ loginning: true }));
          this.store$.dispatch(AuthActions.setAuthType({ authType: AuthType.EMAIL }));
          localStorage.setItem('AUTH_TYPE', AuthType.EMAIL + '');
          this.cdr.detectChanges();
        }
      });

    this.store$
      .select(selectGreenAuthUser)
      .pipe(filter(isPresent), untilDestroyed(this), throttleTime(1000))
      .subscribe((account) => {
        this.greenAuthUser = account;
        this.store$.dispatch(SharedActions.getLanguages());
        this.store$.dispatch(GetUserActions.getUserDetails());
      });

    this.store$
      .select(selectAuthenticatedAccount)
      .pipe(filter(isPresent), untilDestroyed(this), throttleTime(1000))
      .subscribe((account) => {
        this.isAuthenticatedAccount = !!account;
        this.store$.dispatch(SharedActions.getLanguages());
        this.store$.dispatch(GetUserActions.getUserDetails());
      });
  }

  processSuccess(result: any): void {
    console.log(result);
    this.prevSuccessAuthResultToken = result?.payload?.accessToken;

    const message = {
      data: {
        access_token: this.prevSuccessAuthResultToken,
      },
    };

    this.sendMessageToAddIn(message);

    if (this.prevSuccessAuthResultToken !== result?.payload?.accessToken) {
      const payload = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(payload.account);
      this.store$.dispatch(AuthActions.authComplete({ res: payload }));
      this.store$.dispatch(AuthActions.setAuthAccount({ res: payload.account }));
    }
  }

  sendMessageToAddIn(message: any) {
    Office.onReady(function () {
      Office.context?.ui?.messageParent(JSON.stringify(message), {
        targetOrigin: '*',
      });
    });
  }

  setAuthAccount() {
    const account = this.authService.instance.getAllAccounts()?.[0];
    this.store$.dispatch(AuthActions.setAuthAccount({ res: account }));
    this.cdr.detectChanges();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.cdr.detectChanges();
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginEmail() {
    this.store$.dispatch(AuthActions.setLoginning({ loginning: true }));
    this.store$.dispatch(AuthActions.setAuthType({ authType: AuthType.EMAIL }));
    localStorage.setItem('AUTH_TYPE', AuthType.EMAIL + '');

    this.router.navigate([ApplicationPaths.Login], { replaceUrl: true });
  }

  loginPopup() {
    this.store$.dispatch(AuthActions.setLoginning({ loginning: true }));
    this.store$.dispatch(AuthActions.setAuthType({ authType: AuthType.MSAL }));
    localStorage.setItem('AUTH_TYPE', AuthType.MSAL + '');

    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.cdr.detectChanges();
        });
    } else {
      this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
        this.cdr.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
