import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { ApplicationPaths } from './modules/login/api-authorization.constants';
import { LoginComponent } from './modules/login/components/login.component';
import { LogoutComponent } from './modules/login/components/logout.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: ApplicationPaths.Login, component: LoginComponent },
  { path: ApplicationPaths.LoginCallback, component: LoginComponent },
  { path: ApplicationPaths.LogOut, component: LogoutComponent },
  { path: ApplicationPaths.LoggedOut, component: LogoutComponent },
  { path: ApplicationPaths.LogOutCallback, component: LogoutComponent },
  {
    path: 'documents',
    loadChildren: () =>
      import('./modules/documents/documents.module').then((m) => m.DocumentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'playbook',
    loadChildren: () =>
      import('./modules/playbooks/playbooks.module').then((m) => m.PlaybooksModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'questions',
    loadChildren: () =>
      import('./modules/questions/questions.module').then((m) => m.QuestionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'document-types',
    loadChildren: () =>
      import('./modules/doc-types/doc-types.module').then((m) => m.DocTypesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'counterparties',
    loadChildren: () =>
      import('./modules/counterparties/counterparties.module').then((m) => m.CounterpartiesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'baseline-documents',
    loadChildren: () =>
      import('./modules/baseline-documents/baseline-documents.module').then(
        (m) => m.BaselineDocumentsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./modules/templates/templates.module').then((m) => m.TemplatesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
