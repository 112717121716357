<div class="progress-loader" [hidden]="!loading">
  <div class="spinner-border" role="status"></div>
  <div *ngIf="inviting" class="inviting-text">
    <b>
      {{ 'text.invitingHeader' | transloco }}
    </b>
    <br />
    <p>
      {{ 'text.invitingText' | transloco }}
    </p>
  </div>
</div>

<div class="progress-loader non-blocking" [hidden]="!loadingNonBlocking">
  <div class="spinner-border" role="status">
    <!-- <span class="sr-only">Loading...</span> -->
  </div>
</div>
