import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/state';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthActions } from '../store/actions';

@UntilDestroy()
@Injectable()
export class LicenseInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 402) {
          this.store.dispatch(AuthActions.setLicenseExpired({ expired: true }));
          return EMPTY;
        } else return next.handle(request);
      }),
    );
  }
}
