import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter } from 'rxjs';
import {
  selectAuthCode,
  selectAuthenticatedAccount,
  selectGreenAuthUser,
} from 'src/app/core/store/selectors';
import { AppState } from 'src/app/core/store/state';
import { isPresent } from 'src/app/core/utils/isPresent';
import { AuthGreenService, AuthenticationResultStatus } from '../auth-green.service';
import {
  ApplicationPaths,
  LoginGreenActions,
  LogoutGreenActions,
  ReturnUrlType,
} from '../api-authorization.constants';
import { AuthActions } from 'src/app/core/store/actions';

@UntilDestroy()
@Component({
  selector: 'app-login',
  template: '',
})
export class LoginComponent implements OnInit {
  public message = new BehaviorSubject<any>(null);
  public onlyEmail = this.activatedRoute.snapshot.queryParams['onlyEmail'];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store$: Store<AppState>,
    private authGreenService: AuthGreenService,
  ) {
    let onlyEmail = this.activatedRoute.snapshot.queryParams['onlyEmail'] === 'true';
    this.store$.dispatch(AuthActions.setOnlyEmailAuthOption({ onlyEmail }));

    if (onlyEmail) {
      const returnUrl = this.getReturnUrl();
      this.login(returnUrl);
    }
  }

  async ngOnInit(): Promise<void> {
    this.store$
      .select(selectAuthCode)
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe((codeExpression) => {
        this.processLoginCallback();
      });

    this.store$
      .select(selectGreenAuthUser)
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe((account) => {
        this.router.navigate(['/documents'], { replaceUrl: true });
      });

    this.store$
      .select(selectAuthenticatedAccount)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((account) => {
        this.router.navigate(['/documents'], { replaceUrl: true });
      });

    const action = this.activatedRoute.snapshot.url[1];

    switch (action?.path) {
      case LoginGreenActions.Login:
        const returnUrl = this.getReturnUrl();
        const returnedState = this.activatedRoute.snapshot.queryParams['state'];
        const doingSignOut = !!localStorage.getItem('doingSignOut');

        if (returnedState) {
          if (doingSignOut) {
            localStorage.removeItem('doingSignOut');

            Office.onReady(function () {
              Office.context?.ui?.messageParent(JSON.stringify({ data: { loggedOut: true } }), {
                targetOrigin: '*',
              });
            });
          }

          this.router.navigate(['/login']);
        } else {
          await this.login(returnUrl);
        }

        break;
      case LogoutGreenActions.LoggedOut: {
        debugger;
        break;
      }
      default:
    }
  }

  async login(returnUrl: string): Promise<void> {
    const state: INavigationState = { returnUrl };
    const result = await this.authGreenService.signIn(state, this.store$);
  }

  private async processLoginCallback(): Promise<void> {
    const url = window.location.href;
    const result = await this.authGreenService.completeSignIn(url, this.store$);

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        this.sendMessageToAddIn();
        break;
      case AuthenticationResultStatus.Fail:
        this.message.next(result.message);
        break;
    }
  }

  sendMessageToAddIn() {
    const that = this;
    Office.onReady(function () {
      that.store$
        .select(selectGreenAuthUser)
        .pipe(filter(isPresent), untilDestroyed(that))
        .subscribe((user) => {
          Office.context?.ui?.messageParent(JSON.stringify({ data: user }), {
            targetOrigin: '*',
          });
        });
    });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // If the url is coming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(fromQuery.startsWith(`${window.location.origin}/`) || /\/[^\/].*/.test(fromQuery))
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    //return (state && state.returnUrl) || fromQuery || ApplicationPaths.DefaultLoginRedirectPath;
    return ApplicationPaths.DefaultLoginRedirectPath;
  }

  private async navigateToReturnUrl(returnUrl: string): Promise<void> {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true,
    });
  }
}

export interface INavigationState {
  [ReturnUrlType]: string;
}
