import { GetUserActions } from './actions';
import { createReducer, on } from '@ngrx/store';
import { loggedInSuccessAction, AuthActions, logoutAction } from 'src/app/core/store/actions';
import { initialAppState } from 'src/app/core/store/state';

export const authReducer = createReducer(
  initialAppState,
  on(AuthActions.authComplete, (state, { res }) => ({
    ...state,
    authenticationResult: res,
    authenticatedAccount: res.account,
    isLoggedIn: true,
  })),
  on(AuthActions.stopAuthorizing, (state) => ({
    ...state,
    authenticationResult: null,
    authenticatedAccount: null,
    isLoggedIn: false,
    loginning: false,
  })),
  on(AuthActions.setAuthAccount, (state, { res }) => ({
    ...state,
    authenticatedAccount: res,
    isLoggedIn: true,
    // loginning: false,
  })),
  on(AuthActions.setGreenAuthUser, (state, { user }) => ({
    ...state,
    greenAuthUser: user,
    // loginning: false,
  })),
  on(AuthActions.setAuthType, (state, { authType }) => ({
    ...state,
    authType,
  })),
  on(AuthActions.setLoginning, (state, { loginning }) => ({
    ...state,
    loginning,
  })),
  on(AuthActions.setOnlyEmailAuthOption, (state, { onlyEmail }) => ({
    ...state,
    onlyEmailAuthOption: onlyEmail,
  })),
  on(AuthActions.setAuthCode, (state, { authCode }) => ({
    ...state,
    authCode,
  })),
  on(AuthActions.setLicenseExpired, (state, { expired }) => ({
    ...state,
    licenseExpired: expired,
  })),
  on(GetUserActions.getUserDetailsSuccess, (state, { res }) => ({
    ...state,
    user: res,
    loginning: false,
  })),
  on(GetUserActions.getUserDetailsFailure, (state) => ({ ...state, user: null, loginning: false })),
  on(GetUserActions.getUserDetails, (state) => ({ ...state, gettingUserDetails: true })),
  on(GetUserActions.getUserDetailsSuccess, (state, { res }) => ({
    ...state,
    user: res,
    gettingUserDetails: false,
  })),
  on(GetUserActions.getUserDetailsFailure, (state) => ({
    ...state,
    user: null,
  })),
  on(GetUserActions.saveUserDetails, (state, { user }) => ({
    ...state,
    savingUserDetails: true,
  })),
  on(GetUserActions.saveUserDetailsSuccess, (state, { user }) => ({
    ...state,
    savingUserDetails: false,
    user,
  })),
  on(GetUserActions.saveUserDetailsFailure, (state, { error }) => ({
    ...state,
    savingUserDetails: false,
  })),
  on(GetUserActions.setInviting, (state, { inviting }) => ({
    ...state,
    inviting,
  })),
  on(GetUserActions.inviteUser, (state) => ({
    ...state,
    user: null,
    loginning: true,
    inviting: true,
  })),
  on(GetUserActions.inviteUserSuccess, (state, { res }) => ({
    ...state,
    user: res,
    gettingUserDetails: false,
    loginning: false,
    inviting: false,
  })),
  on(GetUserActions.inviteUserFailure, (state) => ({
    ...state,
    user: null,
    loginning: false,
    inviting: false,
  })),
  on(logoutAction, (state) => ({
    ...state,
    user: null,
    isLoggedIn: false,
  })),
  on(loggedInSuccessAction, (state) => ({ ...state, isLoggedIn: true })),
  on(AuthActions.setClauseNameToBeFiltered, (state, { name }) => ({
    ...state,
    clauseNameToBeFiltered: name,
  })),
);
