import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthGreenService } from '../auth-green.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(
    private cookieService: CookieService,
    private authGreenService: AuthGreenService,
  ) {}

  async ngOnInit(): Promise<void> {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll('/');
    localStorage.setItem('doingSignOut', 'true');
    this.authGreenService.signOutCalledFromAddIn();
  }
}
