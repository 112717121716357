import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { AppState } from '../../store/state';
import { Store } from '@ngrx/store';
import { selectInviting, selectIsGettingUserDetails } from '../../store/selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPresent } from '../../utils/isPresent';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {
  public isGettingUserDetails: boolean = false;
  public loading: boolean = false;
  public loadingNonBlocking: boolean = false;
  public inviting: boolean = false;

  constructor(
    private spinnerService: SpinnerService,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {
    this.spinnerService.isLoading.subscribe((loading) => {
      this.loading = this.isGettingUserDetails === true ? true : loading;
      this.cdr.markForCheck();
    });

    this.spinnerService.isLoadingNonBlocking.subscribe((loading) => {
      this.loadingNonBlocking = loading;
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.store$
      .select(selectIsGettingUserDetails)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((isGetting) => {
        this.isGettingUserDetails = isGetting;
        this.cdr.detectChanges();

        // no need, we have extra requests after getting user details
        // if (isGetting === false && this.loading === true) {
        //   debugger;
        // }
      });

    this.store$
      .select(selectInviting)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((inviting) => {
        this.inviting = inviting;
        this.cdr.detectChanges();
      });
  }
}
