import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { notificationsFeatureKey } from './selectors';
import { notificationFeatureReducer } from './reducer';
import { NotificationFeatureEffects } from './effects';

@NgModule({
  imports: [
    StoreModule.forFeature(notificationsFeatureKey, notificationFeatureReducer),
    EffectsModule.forFeature([NotificationFeatureEffects]),
  ],
})
export class NotificationsStoreModule {}
