<tui-root>
  <div id="app">
    <app-spinner></app-spinner>
    <div>
      <div class="main-container">
        @if (isAuthenticatedAccount || greenAuthUser) {
          <div class="left-side-menu">
            <app-left-side-menu></app-left-side-menu>
          </div>
        }

        <div class="main-content">
          @if (isLicenseExpired === true) {
            <div class="message-license-expired" role="alert">
              <div></div>
              <div>
                <strong> {{ 'license.expiredTitle' | transloco }} </strong><br /><br />
                {{ 'license.pleaseRenew' | transloco }} <br /><br />
                {{ 'license.contactSupportAt' | transloco }}
                <a [href]="'mailto:' + constants.supportEmail">{{ constants.supportEmail }}</a>
                {{ 'license.orVisit' | transloco }}
                <a [href]="constants.supportUrl" target="_blank">{{ constants.supportUrl }}</a>
                {{ 'license.forAssistance' | transloco }} <br /><br />{{
                  'license.thanks' | transloco
                }}
              </div>
            </div>
          } @else {
            <router-outlet></router-outlet>
          }

          @if (!isAuthenticatedAccount && !greenAuthUser) {
            <div class="logo"></div>
            <div class="app-login__card">
              @if (!onlyEmailAuthOption) {
                <h4>{{ 'titles.signIn' | transloco }}</h4>
                <p>
                  {{ 'text.pleaseSignIn' | transloco }}
                  <a href="https://www.autolex.ai/en/support" target="_blank" rel="noopener"
                    >{{ 'text.moreInfo' | transloco }}
                  </a>
                </p>
                <button
                  class="ms-button"
                  [disabled]="loginning"
                  (click)="loginPopup()"
                  *ngIf="!onlyEmailAuthOption"
                >
                  <span class="ms-icon"></span>
                  <span class="ms-text">{{ 'buttons.loginMs' | transloco }}</span>
                </button>
                <button class="ms-button" [disabled]="loginning" (click)="loginEmail()">
                  <span class="mail-icon"></span>
                  <span class="ms-text">{{ 'buttons.loginEmail' | transloco }}</span>
                </button>
              }

              @if (onlyEmailAuthOption) {
                <h4>{{ 'titles.signIn' | transloco }}</h4>
                <p>
                  {{ 'text.pleaseSignIn' | transloco }}
                </p>
                <button
                  class="ms-button"
                  [disabled]="loginning"
                  (click)="loginPopup()"
                  *ngIf="!onlyEmailAuthOption"
                >
                  <span class="mail-icon"></span>
                  <span class="ms-text">{{ 'buttons.loginTrialAccount' | transloco }}</span>
                </button>
              }

              @if (loginning) {
                <mat-icon class="material-symbols-rounded rotating">{{
                  'progress_activity'
                }}</mat-icon>
              }
            </div>
          }
          @if (!isUserDetails) {
            <!-- <h4 class="log-in-text">{{ 'text.loading' | transloco }}</h4> -->
          }
        </div>
      </div>
    </div>
  </div>

  <mat-icon class="material-symbols-rounded transparent">{{ 'progress_activity' }}</mat-icon>
</tui-root>
