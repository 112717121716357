import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environment/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}

// https://github.com/angular/angular/issues/54420
// in office add-ins those are replaced with null and cause router to crash, so we set them to empty functions
// window.history.replaceState ??= () => {};
// window.history.pushState ??= () => {};

Office.onReady().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
