import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { DialogsActions } from './actions';
import { MatDialog } from '@angular/material/dialog';
import { WINDOW } from '@ng-web-apis/common';
import { AppDialogComponent } from 'src/app/shared/components/modals/app-dialog/app-dialog.component';

@Injectable()
export class DialogEffects {
  showDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DialogsActions.showDialog),
        tap(({ data, callback }) => {
          this.window.scroll({ top: 0 });
          const ref = this.dialog.open(AppDialogComponent, {
            data,
          });

          if (callback) {
            ref.afterClosed().subscribe((result) => callback(result));
          }
        }),
      ),
    { dispatch: false },
  );
  constructor(
    private readonly actions$: Actions,
    public dialog: MatDialog,
    @Inject(WINDOW) readonly window: Window,
  ) {}
}
