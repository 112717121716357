export const ApplicationName = 'AutoLex_WebApp_WordAddin';
export const ReturnUrlType = 'returnUrl';
export const QueryParameterNames = {
  ReturnUrl: ReturnUrlType,
  Message: 'message',
};

export const LoginGreenActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
};
export const LogoutGreenActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

let applicationPaths: ApplicationPathsType = {
  DefaultLoginRedirectPath: '/documents',
  Login: `authentication/${LoginGreenActions.Login}`,
  LoginCallback: `authentication/${LoginGreenActions.LoginCallback}`,
  LogOut: `authentication/${LogoutGreenActions.Logout}`,
  LoggedOut: `authentication/${LogoutGreenActions.LoggedOut}`,
  LogOutCallback: `authentication/${LogoutGreenActions.LogoutCallback}`,
};

applicationPaths = {
  ...applicationPaths,
};

interface ApplicationPathsType {
  readonly DefaultLoginRedirectPath: string;
  readonly Login: string;
  readonly LoginCallback: string;
  readonly LogOut: string;
  readonly LoggedOut: string;
  readonly LogOutCallback: string;
}

export const ApplicationPaths: ApplicationPathsType = applicationPaths;
