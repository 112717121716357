import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, mergeMap, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/state';
import { selectGreenAuthUser } from '../store/selectors';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthGreenService, AuthType } from 'src/app/modules/login/auth-green.service';
import {
  ApplicationPaths,
  QueryParameterNames,
} from 'src/app/modules/login/api-authorization.constants';
import { Router } from '@angular/router';

@UntilDestroy()
@Injectable()
export class GreenInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private authGreenService: AuthGreenService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authType = localStorage.getItem('AUTH_TYPE') || '';

    return this.authGreenService.getAccessToken().pipe(
      switchMap((access_token) => {
        if (access_token) {
          if (+authType === AuthType.EMAIL) {
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${access_token}` },
            });
          }

          return next.handle(request).pipe(
            catchError((error) => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                debugger;
                this.router.navigate([ApplicationPaths.Login], {
                  queryParams: {
                    [QueryParameterNames.ReturnUrl]: '/documents',
                  },
                });
              }
              return throwError(error);
            }),
          );
          // return this.authGreenService
          //   .getAccessToken()
          //   .pipe(mergeMap((token) => this.processRequestWithToken(token, request, next)));
        } else {
          return this.authGreenService
            .getAccessToken()
            .pipe(mergeMap((token) => this.processRequestWithToken(token, request, next)));
        }
      }),
    );
  }

  private processRequestWithToken(
    token: string | null,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!!token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.router.navigate([ApplicationPaths.Login], {
            queryParams: {
              [QueryParameterNames.ReturnUrl]: req.url,
            },
          });
        }
        return throwError(error);
      }),
    );
  }
}
