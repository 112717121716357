import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { map, Observable, take, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/state';
import { selectAuthenticatedAccount, selectGreenAuthUser } from '../store/selectors';
import { AuthType } from 'src/app/modules/login/auth-green.service';
import { constants } from '../constants/constants';
import { MsalGuard } from '@azure/msal-angular';

// This guard run green or msal canActivate logic

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private msalGuard: MsalGuard,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const savedAuthType = localStorage.getItem(constants.authTypeKey) || '';

    if (+savedAuthType === AuthType.EMAIL) {
      return this.store.select(selectGreenAuthUser).pipe(
        withLatestFrom(this.store.select(selectAuthenticatedAccount)),
        map(([greenAuthUser, msalAuthUser]) => {
          if (greenAuthUser || msalAuthUser) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        }),
        take(1),
      );
    } else return this.msalGuard.canActivate(route, state);
  }
}
