import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { dialogFeatureKey } from './selectors';
import { dialogReducer } from './reducer';
import { DialogEffects } from './effects';

@NgModule({
  imports: [
    StoreModule.forFeature(dialogFeatureKey, dialogReducer),
    EffectsModule.forFeature([DialogEffects]),
  ],
})
export class DialogsStoreModule {}
