import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { AuthActions, GetUserActions } from './actions';
import { catchError, debounceTime, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { UserApiService } from 'src/app/modules/login/services/user-api.service';
import { AppState } from './state';
import { Store } from '@ngrx/store';
import { isPresent } from '../utils/isPresent';
import { Router } from '@angular/router';
import { selectClauseNameToBeFiltered, selectOnlyEmailAuthOption } from './selectors';
import { DialogsActions } from 'src/app/modules/dialogs/store/actions';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class GetUserDetailsEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private userApiService: UserApiService,
    private translocoService: TranslocoService,
  ) {}

  getUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUserActions.getUserDetails),
      mergeMap(() =>
        this.userApiService.getUserDetails$().pipe(
          map((res) => GetUserActions.getUserDetailsSuccess({ res })),
          catchError((error) => of(GetUserActions.getUserDetailsFailure({ error }))),
        ),
      ),
    ),
  );

  saveUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUserActions.saveUserDetails),
      mergeMap((props) =>
        this.userApiService.saveUserDetails$(props.user).pipe(
          map((user) => {
            this.store.dispatch(GetUserActions.getUserDetailsSuccess({ res: user }));
            return GetUserActions.saveUserDetailsSuccess({ user });
          }),
          catchError((response) => {
            return of(
              GetUserActions.saveUserDetailsFailure(response),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: this.translocoService.translate('errors.saveUserDetails'),
                },
              }),
            );
          }),
        ),
      ),
    ),
  );

  getUserDetailsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUserActions.getUserDetailsFailure),
      withLatestFrom(this.store.select(selectOnlyEmailAuthOption)),
      mergeMap(([error, onlyEmail]) => {
        if (error.error.status === 404) {
          this.store.dispatch(GetUserActions.setInviting({ inviting: true }));
          if (!onlyEmail) {
            return this.userApiService.inviteUser$().pipe(
              map((res) => GetUserActions.inviteUserSuccess({ res })),
              catchError((error) => of(GetUserActions.inviteUserFailure({ error }))),
            );
          } else {
            return of(GetUserActions.inviteUserFailure(error));
          }
        } else {
          return of(GetUserActions.inviteUserFailure(error));
        }
      }),
    ),
  );

  setAuthAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setAuthAccount),
      debounceTime(500),
      withLatestFrom(this.store.select(selectClauseNameToBeFiltered).pipe(filter(isPresent))),
      map(([props, clauseName]) => {
        if (clauseName) {
          this.router.navigate([`playbook`]);
        }

        return AuthActions.clauseNameCheckedSuccess();
      }),
    ),
  );
}
